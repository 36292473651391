<template>
  <div class="vote">
    <h3>投票</h3>
    <p>站长提示：请仔细阅读下方文字并进行投票，点亮屎黄色进度条。每天都可以投一次哦～</p>
    <ul>
      <li>
        <label>面试被问到了</label>
        <el-progress :stroke-width="15" :percentage="50"></el-progress>
        <span>50% · 31211票 · 今日已投</span>
      </li>
      <li>
        <label>面试被问倒了</label>
        <el-progress :stroke-width="15" :percentage="0"></el-progress>
        <span>50% · 3121票 · <em>点击投票</em></span>
      </li>
      <li>
        <label>面试没被问到</label>
        <el-progress :stroke-width="15" :percentage="0"></el-progress>
        <span>50% · 3121票 · <em>点击投票</em></span>
      </li>
      <li>
        <label>我把面试官问倒了</label>
        <el-progress :stroke-width="15" :percentage="0"></el-progress>
        <span>50% · 3121票 · <em>点击投票</em></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Vote'
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.vote {
  h3 {
    margin: 0;
  }
  p {
    color: #999999;
    font-size: 12px;
  }
  ul {
    padding: 0;
    li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      align-items: center;
      cursor: pointer;
      &:hover {
        .el-progress {
          /deep/ .el-progress-bar__inner {
            transition: all ease .3s;
            background-color: $primary-color;
            opacity: 1;
          }
        }
        span {
          color: $font-color;
        }
      }
      label {
        flex-shrink: 0;
        width: 100%;
        cursor: pointer;
      }
      .el-progress {
        width: 100%;
        margin: 5px 0;
        /deep/ .el-progress-bar {
          padding-right: 0;
        }
        /deep/ .el-progress-bar__inner {
          transition: all ease .3s;
          opacity: .3;
        }
        /deep/ .el-progress__text {
          font-size: 14px !important;
        }
      }
      span {
        width: 100%;
        flex-shrink: 0;
        color: $font-color-third;
        em {
          font-style: normal;
        }
      }
    }
  }
}
</style>
