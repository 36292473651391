<template>
  <div class="continue-questions">
    <h3>
      面试连炮
      <Icon
        type="el-icon-question"
        tip-title="面试连炮"
        tip-width="200"
        tip-content='面试连炮即问完当前问题后可能会继续询问的问题。'
      />
    </h3>
    <Loading v-if="loading"/>
    <EmptyTip v-else-if="data.length === 0"/>
    <ul v-else>
      <li v-for="question in data" :key="question.serialNo">
        <a :href="'/question/' + question.serialNo" target="_blank">{{question.title}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { fetchContinueQuestionList } from '@/api/question'

export default {
  name: 'ContinueQuestions',
  props: {
    // 问题编号
    serialNo: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      data: [],
      pagination: {
        page: 1,
        capacity: 10
      }
    }
  },
  methods: {
    // 查询连炮问题
    fetchContinueQuestionList () {
      this.loading = true
      fetchContinueQuestionList({
        ...this.pagination,
        model: this.serialNo
      })
        .then(data => {
          this.data = data.records
        })
        .catch(() => {
          this.data = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.fetchContinueQuestionList()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.continue-questions {
  h3 {
    margin: 0 0 10px 0;
    /deep/ .icon {
      color: $font-color-third;
      position: relative;
      top: -1px;
      &:hover {
        color: $font-color;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      width: 100%;
      padding: 8px 0;
    }
  }
  .empty-tip {
    min-height: 200px;
  }
}
</style>
