<template>
  <iframe
    :src="src"
    scrolling="no"
    border="0"
    frameborder="no"
    framespacing="0"
    allowfullscreen="true"
  ></iframe>
</template>

<script>
export default {
  name: 'BVideo',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
iframe {
  width: 100%;
  height: 100%;
}
</style>
