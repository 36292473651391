<template>
  <div class="question-catalog">
    <h3>目录</h3>
    <ul>
      <li v-for="h1 in catalogs" :key="h1.id">
        <span
          :class="{ selected: current != null && current.id === h1.id }"
          @click="$emit('catalog-change', h1)"
        >
          {{h1.title}}
        </span>
        <ul v-if="h1.children.length > 0">
          <li v-for="h2 in h1.children" :key="h2.title">
            <span
              :class="{ selected: current != null && current.id === h2.id }"
              @click="$emit('catalog-change', h2)"
            >
              {{h2.title}}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuestionCatalog',
  props: {
    // 目录数据
    data: {
      type: Array,
      required: true
    },
    // 当前
    current: {
      required: false
    }
  },
  computed: {
    // 归纳标题
    catalogs () {
      const catalogs = []
      for (const item of this.data) {
        const catalog = JSON.parse(JSON.stringify(item))
        if (catalog.tag.toLowerCase() === 'h1') {
          catalog.children = []
          catalogs.push(catalog)
        }
        if (catalog.tag.toLowerCase() === 'h2') {
          catalogs[catalogs.length - 1].children.push(catalog)
        }
      }
      return catalogs
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.question-catalog {
  h3 {
    margin: 0 0 10px 0;
  }
  & > ul {
    padding: 0;
    margin: 0;
    /* 一级菜单 */
    & > li {
      & > .selected {
        &::before {
          left: -5px !important;
        }
      }
    }
    ul {
      padding-left: 15px;
      margin: 0;
    }
    li {
      display: block;
      height: auto;
      span {
        display: block;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        transition: all ease .3s;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          transition: all ease .3s;
          background-color: $background-color-second;
        }
        &.selected {
          color: $primary-color;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: -20px;
            top: 14px;
            border-style: solid;
            border-width: 6px;
            border-color: transparent;
            border-left-color: $primary-color;
          }
        }
      }
    }
  }
}
</style>
