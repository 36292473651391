<template>
  <div class="question-detail" v-if="data != null">
    <div class="main">
      <!-- 题目标题 -->
      <div class="block title-block">
        <h2>{{data.title}}</h2>
        <span>
          <Icon type="icon-chakan2"/>
          <em>{{data.readCount}}</em>
        </span>
        <span>
          <Icon type="icon-shoucang"/>
          <em>{{data.favoriteCount}}</em>
        </span>
      </div>
      <!-- 题目讲解 -->
      <div class="introduce-block">
        <QuestionAnswerView ref="questionAnswerView" :value="data.answer.content" @catalog-prepared="handleCatalogPrepared" @catalog-change="handleCatalogChange"/>
        <!-- 参考资料 -->
        <div v-if="data.refMaterials.length > 0" class="ref-material-block">
          <h3>参考资料</h3>
          <ul>
            <li v-for="(refMaterial,index) in data.refMaterials" :key="refMaterial.id">
              <label>{{index+1}}.</label><Link :href="refMaterial.url">{{refMaterial.name}}</Link>
            </li>
          </ul>
        </div>
      </div>
      <BreakLine v-if="data.videos.length > 0"/>
      <!-- 视频讲解 -->
      <div class="block" v-if="data.videos.length > 0">
        <VideoExplain :type="data.videos[0].type" :src="data.videos[0].url"/>
      </div>
      <BreakLine/>
      <!-- 讨论区 -->
      <div class="block comment-block">
        <CommentWindow target-data-type="question" :target-data-id="data.id" :target-data-serial-no="data.serialNo"/>
      </div>
    </div>
    <div class="side">
      <!-- 投票 -->
      <div class="block">
        <Vote/>
      </div>
      <!-- 问了又问 -->
      <div class="block" v-if="data.continueQuestionCount > 0">
        <ContinueQuestions :serial-no="$route.params.code"/>
      </div>
      <!-- 阅读目录 -->
      <div v-if="catalogData.list.length > 0" class="block catalog-block">
        <QuestionCatalog :data="catalogData.list" :current="catalogData.current" @catalog-change="toTargetTitle"/>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchQuestionDetail } from '@/api/question'
import Vote from '@/components/question/Vote'
import CommentWindow from '@/components/common/CommentWindow'
import BreakLine from '@/components/common/BreakLine'
import VideoExplain from '@/components/question/VideoExplain'
import QuestionAnswerView from '@/components/question/QuestionAnswerView'
import ContinueQuestions from '@/components/question/ContinueQuestions'
import QuestionCatalog from '@/components/question/QuestionCatalog'
export default {
  components: { QuestionCatalog, ContinueQuestions, QuestionAnswerView, VideoExplain, BreakLine, CommentWindow, Vote },
  data () {
    return {
      data: null,
      // 目录
      catalogData: {
        list: [],
        current: null
      }
    }
  },
  methods: {
    // 获取问题详情
    fetchQuestionDetail () {
      fetchQuestionDetail(this.$route.params.code)
        .then(data => {
          this.data = data
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 处理目录准备完成
    handleCatalogPrepared (catalogs) {
      this.catalogData.list = catalogs
    },
    // 处理目录切换
    handleCatalogChange (catalog) {
      this.catalogData.current = catalog
    },
    // 跳转至指定标题
    toTargetTitle (title) {
      this.$refs.questionAnswerView.scrollToTitle(title)
    }
  },
  created () {
    this.fetchQuestionDetail()
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.question-detail {
  margin: 15px 0;
  box-sizing: border-box;
  display: flex;
  .main {
    flex-grow: 1;
    background-color: $background-color;
  }
  .side {
    width: 280px;
    flex-shrink: 0;
    margin-left: 10px;
    .block {
      padding: 20px 15px;
      background-color: $background-color;
      margin-bottom: 10px;
    }
    .catalog-block {
      position: sticky;
      top: 20px;
    }
  }
  .block {
    padding: 0 20px;
  }
  // 题目标题
  .title-block {
    padding: 20px;
    border-bottom: 1px solid $font-color-forth;
    &:first-of-type span {
      margin-right: 20px;
    }
    h2 {
      font-weight: normal;
      font-size: 26px;
    }
    span {
      color: $font-color-third;
      em {
        vertical-align: middle;
        font-style: normal;
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
  // 题目讲解
  .introduce-block {
    padding: 20px;
  }
  // 参考资料
  .ref-material-block {
    h3 {
      font-weight: normal;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        label {
          width: 20px;
          line-height: 26px;
          text-align: right;
          flex-shrink: 0;
          margin-right: 5px;
          vertical-align: middle;
          color: $font-color-third;
        }
        a {
          vertical-align: middle;
          text-decoration: underline;
        }
      }
    }
  }
  // 视频讲解
  .comment-block {
    padding-bottom: 20px;
  }
}
</style>
