<template>
  <div class="video-explain">
    <h3>视频讲解</h3>
    <div>
      <BVideo v-if="type === 'B'" :src="src"/>
      <VideoPlayer v-if="type === 'SITE'" :uri="'/api' + src" :autoplay="false"/>
      <div v-if="userInfo == null" class="shade">
        <NoLoginTip class="shade" tip="登录后才可以查看视频讲解哦~" color="#ccc" :with-opacity="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BVideo from '@/components/common/BVideo'
import BaseUser from '@/components/base/BaseUser'
import NoLoginTip from '@/components/common/NoLoginTip'
import VideoPlayer from '@/components/common/VideoPlayer'
export default {
  name: 'VideoExplain',
  components: { VideoPlayer, NoLoginTip, BVideo },
  extends: BaseUser,
  props: {
    // 视频类型
    type: {
      type: String,
      required: true
    },
    // 视频地址
    src: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style scoped lang="scss">
.video-explain {
  & > div {
    position: relative;
    height: 500px;
    .shade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
